@import '@/styles/variables';

.details {
  --background-color: var(--color-white);
  --text-color: var(--color-black);
  --label-color: var(--light-gray);
  --borders-color: var(--light-color-gray-10);

  @include box-shadow;

  position: relative;
  width: auto;
  max-width: max(400px, 50vw);
  padding: 20px;
  overflow: hidden;
  color: var(--text-color);
  background-color: var(--background-color);
  border-radius: 10px;


  &::before {
    position: absolute;
    bottom: 19px;
    z-index: 1;
    width: 100%;
    height: 3px;
    content: '';
    background-color: var(--color-white);
  }

  &__wrapper {
    min-height: 50px;
    max-height: 90vh;
    overflow-y: auto;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
  }

  &_dark {
    --background-color: var(--color-black);
    --borders-color: var(--color-gray-60);
    --text-color: var(--color-white);
  }
}

.content-block {
  position: relative;
  flex-grow: 1;
  min-width: 80px;
  font-size: 14px;

  &:not(:last-child) {
    padding-bottom: 8px;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: var(--borders-color);
  }

  &::before {
    position: absolute;
    top: 0;
    left: -10px;
    width: 1px;
    height: 100%;
    content: '';
    background-color: var(--borders-color);
  }

  &_full-width {
    min-width: 100%;
  }

  &__label {
    padding-bottom: 5px;
    color: var(--label-color);
  }
}

.array-details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px 15px;
  width: 100%;

  &__item {
    display: contents;
  }
}

.value {
  display: inline-block;
  min-width: 200px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}

.title {
  color: var(--label-color);
  word-wrap: break-word;
}

.button-wrapper {
  width: max-content;
}
